import 'notyf/notyf.min.css';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import App from './App';
import LoginButton from './Routes/LoginButton';
import useStore from './Store/Store';

interface AuthAppProps {
  devMode: boolean;
}
const AuthApp = observer(({ devMode }: AuthAppProps) => {
  const { appStore, authStore } = useStore();
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const IdTokenClaims: Promise<IdToken> = getIdTokenClaims();
  useEffect(() => {
    authStore.setTokenFunction(getAccessTokenSilently);
    // Store info about the user in store
    IdTokenClaims.then((token: IdToken) => {
      if (token && token['https://cagesense.cloud/cagesense/isAdmin'] !== undefined) {
        setIsAdmin(token['https://cagesense.cloud/cagesense/isAdmin']);
      }
      appStore.setUser(null, user);
    });
  }, [IdTokenClaims, setIsAdmin, user, appStore, authStore, getAccessTokenSilently]);

  if (!devMode && isLoading) {
    return <Loader active />;
  } else if (isAuthenticated) {
    return <App devMode={devMode} isAdmin={isAdmin} />;
  } else {
    return <LoginButton />;
  }
});

export default AuthApp;
