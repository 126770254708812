import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Icon, Loader, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import useSWR from 'swr';
import { AuthFetcher } from '../../lib/fetch';
import { TopsideStatusContents } from '../../lib/types';
import useStore from '../../Store/Store';

const isStatusGood = (status: TopsideStatusContents) => {
  return (
    status.barometer.status &&
    status.cloud.status &&
    status.receivers.status &&
    status.sensors.status &&
    status.time_sync.status
  );
};

const numberOfErrors = (status: any) => {
  const statusFields = Object.keys(status).filter((e) => e !== 'topside_id');
  return statusFields.filter((field) => status[field].status === false).length;
};

interface TopsideStatusProps {
  compact?: boolean;
}

const TopsideStatus = observer(({ compact }: TopsideStatusProps) => {
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/status/', (url) => AuthFetcher(url, authStore.getTokenFunction()), {
    refreshInterval: 100,
  });
  let iconColor: SemanticCOLORS = 'yellow';
  let iconType: SemanticICONS = 'question';
  let statusText = '';
  if (!data) {
    statusText = 'Loading...';
    return <Loader active></Loader>;
  } else {
    iconColor = 'green';
    iconType = 'check';
  }
  if (error) {
    iconColor = 'red';
    iconType = 'cancel';
    statusText = 'Error occurred reading data...';
  }
  let status: TopsideStatusContents = data;
  const errorCount = numberOfErrors(status);
  if (isStatusGood(status)) {
    iconColor = 'green';
    iconType = 'check';
    statusText = 'All components OK';
  } else {
    iconColor = 'black';
    iconType = 'exclamation triangle';
    statusText = `${errorCount} issue(s)...`;
  }
  return (
    <Link to="/">
      <Button compact={compact} fluid color={errorCount > 0 ? 'red' : 'blue'}>
        {!compact && <span>System status</span>}
        <Icon name={iconType} color={iconColor} />
        {statusText}
      </Button>
    </Link>
  );
});

export default TopsideStatus;
