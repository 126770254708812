import React from 'react';
import ReactDOM from 'react-dom';
import './css/styles.css';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-less/semantic.less';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthApp from './AuthApp';
import TopsideApp from './TopsideApp';
import ReactGA from 'react-ga';

const enableLogin = process.env.REACT_APP_ENABLE_LOGIN === '1'
if (enableLogin) {
  const AUTH0_CLIENT_ID = process.env['REACT_APP_AUTH0_CLIENT_ID'] || '';
  const AUTH0_DOMAIN = process.env['REACT_APP_AUTH0_DOMAIN'] || '';
  const AUTH0_AUDIENCE = process.env['REACT_APP_AUTH0_AUDIENCE'] || '';
  const GOOGLE_ANALYTICS_ID = process.env['REACT_APP_GOOGLE_ANALYTICS_ID'] || ''

  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  })
  ReactGA.pageview(window.location.pathname + window.location.search)

  ReactDOM.render(
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      scope="read:current_user"
    >
      <React.StrictMode>
        <AuthApp devMode={enableLogin} />
      </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
  );
} else {
  // We're rendering a local Gateway application
  ReactDOM.render(
    <React.StrictMode>
      <TopsideApp devMode={enableLogin} />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
