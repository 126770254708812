import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Card, Grid, Header, Icon, Loader } from 'semantic-ui-react';
import useSWR from 'swr';
import { AuthFetcher } from '../lib/fetch';
import { TopsideStatusContents as GatewayStatusContents } from '../lib/types';
import useStore from '../Store/Store';

const StatusCard = ({ data }: { data: any }) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Icon name={data.icon} className="me-1" />
          {data.name}
        </Card.Header>
        <Card.Description textAlign="center">
          <Icon
            name={data.info.status === true ? 'check' : 'exclamation triangle'}
            color={data.info.status === true ? 'green' : 'red'}
            size="large"
            className="mt-1 mb-2"
          />
          <p className="text-lg">{data.info.message}</p>
          <Link to={data.link}>
            More details
            <Icon name="chevron right" />
          </Link>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

const allSystemsOperational = (status: GatewayStatusContents) => {
  return (
    status.barometer.status &&
    status.cloud.status &&
    status.receivers.status &&
    status.sensors.status &&
    status.time_sync.status
  );
};

const GatewayOverallStatus = observer(() => {
  // Initial status is void, as we haven't received results yet
  let allSystemsOk = null;
  const { authStore } = useStore();
  const { data, error } = useSWR('/api/v1/status/', (url) => AuthFetcher(url, authStore.getTokenFunction()), {
    refreshInterval: 100,
  });

  if (!data) {
    return <Loader active />;
  } else {
    allSystemsOk = allSystemsOperational(data);
  }
  if (error) {
    allSystemsOk = false;
  }

  let status: GatewayStatusContents = data;
  const statusElements = [
    {
      name: 'Water Linked Cloud',
      icon: 'theme',
      info: status.cloud,
      link: '/about#hardware',
    },
    {
      name: 'Receivers',
      icon: 'plug',
      info: status.receivers,
      link: '/diagnostic',
    },
    {
      name: 'Sensor',
      icon: 'wifi',
      info: status.sensors,
      link: '/diagnostic',
    },
    {
      name: 'Barometer',
      icon: 'thermometer half',
      info: status.barometer,
      link: '/diagnostic',
    },
    {
      name: 'Time Sync',
      icon: 'clock',
      info: status.time_sync,
      link: '/time',
    },
  ];
  return (
    <div>
      <Grid centered className="my-3">
        <Grid.Column computer={12} mobile={16}>
          <Header size="large" color={allSystemsOk === true ? 'green' : 'red'}>
            Overall Status
          </Header>
          <p className="text-white text-lg">
            {allSystemsOk !== true
              ? 'One or more components are showing errors.'
              : 'All components are working as they should.'}
          </p>
          <Card.Group itemsPerRow={3} stackable>
            {statusElements.map((e) => {
              return <StatusCard data={e} />;
            })}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </div>
  );
});

export default GatewayOverallStatus;
