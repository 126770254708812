import { createMedia } from '@artsy/fresnel';
import { range } from 'd3-array';
import { min, max, extent } from 'd3-array';
import { axisLeft, axisBottom, axisTop, Axis } from 'd3-axis';
import { NumberValue, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { observer } from 'mobx-react';
import React, { useRef, useEffect, useState, Dispatch, SetStateAction, FC, Fragment } from 'react';
import { Popup, Icon, Input, Radio, Form, Divider, CheckboxProps, InputOnChangeData, Loader } from 'semantic-ui-react';
import useSWR from 'swr';
import { AuthFetcher } from '../../lib/fetch';

import { DiagnosticData } from '../../lib/types';
import useStore from '../../Store/Store';
import './CorrelatorGraph.css';

const receiver_colors = ['#0000ff', '#00ff00', '#ff0000', '#000000'];
const legendKeys = ['r1', 'r2', 'r3', 'r4'];

interface axisConstructorProps {
  axisCreator: Axis<NumberValue>;
}

const AxisConstructor = ({ axisCreator }: axisConstructorProps) => {
  // https://stackoverflow.com/a/56029853
  const axisRef = (axis: any) => {
    axis && axisCreator(select(axis));
  };
  return <g className="axis" ref={axisRef} />;
};

interface graphConstructorProps {
  graphCreator: Axis<NumberValue>;
  tickSize: number;
}

const GraphGridConstructor = ({ graphCreator, tickSize }: graphConstructorProps) => {
  graphCreator.tickSize(tickSize).tickFormat();

  // https://stackoverflow.com/a/56029853
  const axisRef = (axis: any) => {
    axis && graphCreator(select(axis));
  };
  return <g className="customGrid" ref={axisRef} />;
};

type GridLayoutPopupProps = {
  graphDisplay: string;
  setGraphAxisType: Dispatch<SetStateAction<string>>;
  customYMn: number;
  customYMx: number;
  setCustomYMin: Dispatch<SetStateAction<number>>;
  setCustomYMax: Dispatch<SetStateAction<number>>;
};

const GridLayoutPopup: FC<GridLayoutPopupProps> = ({
  graphDisplay,
  setGraphAxisType,
  customYMn,
  customYMx,
  setCustomYMin,
  setCustomYMax,
}) => {
  const onRadioEvent = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    let dataString: string = data.value as string;
    setGraphAxisType(dataString);
  };

  const onYMnValueChange = (e: React.FormEvent<HTMLInputElement>, data: InputOnChangeData) => {
    console.log('setCustomYMin', data.value);
    setCustomYMin(parseInt(data.value));
  };

  const onYMxValueChange = (e: React.FormEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setCustomYMax(parseInt(data.value));
  };

  const GridLayoutOptions = () => {
    return (
      <Form>
        <Form.Field>Axis options</Form.Field>
        <Form.Field>
          <Radio
            label="Auto"
            name="radioGroup"
            value="auto"
            checked={graphDisplay === 'auto'}
            onChange={onRadioEvent}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Fixed"
            name="radioGroup"
            value="fixed"
            checked={graphDisplay === 'fixed'}
            onChange={onRadioEvent}
          />
        </Form.Field>
        <Divider />
        <Form.Field>
          <Input
            label="Y Max"
            style={{ width: '30%' }}
            size="mini"
            defaultValue={customYMx}
            onChange={onYMxValueChange}
            disabled={graphDisplay !== 'fixed'}
          />
        </Form.Field>
        <Form.Field>
          <Input
            label="Y Min"
            style={{ width: '30%' }}
            size="mini"
            defaultValue={customYMn}
            onChange={onYMnValueChange}
            disabled={graphDisplay !== 'fixed'}
          />
        </Form.Field>
      </Form>
    );
  };

  return (
    <Popup
      content={GridLayoutOptions}
      on="click"
      trigger={<Icon name="setting" style={{ float: 'right' }} color="grey" />}
      position="top right"
    />
  );
};

type LegendsProps = {
  legendsTransform: string;
  mod: number;
};

const Legends: FC<LegendsProps> = ({ legendsTransform, mod }) => {
  if (mod === 0) return <></>;

  // All base values for outerWidth = 1500, outerHeight = 600
  let legendTitleFont = 16 * mod;
  let legendTextFont = 12 * mod;
  let ltx = 32 * mod;
  let lty = -10 * mod;
  let circleR = 6 * mod;
  let circleX = 40 * mod;
  let circleY = 10 * mod;
  let textX = 60 * mod;
  let textY = 15 * mod;
  let yDelta = 25 * mod;

  return (
    <g width="13%" height="40%" transform={legendsTransform}>
      <text x={ltx} y={lty} style={{ fontSize: legendTitleFont + 'px', textDecorationLine: 'underline' }}>
        Receivers
      </text>
      <circle cx={circleX} cy={circleY} r={circleR} fill={receiver_colors[0]} />
      <text className="legendstext" x={textX} y={textY} style={{ fontSize: legendTextFont + 'px', fontWeight: 'bold' }}>
        {legendKeys[0]}
      </text>
      <circle cx={circleX} cy={circleY + yDelta} r={circleR} fill={receiver_colors[1]} />
      <text
        className="legendstext"
        x={textX}
        y={textY + yDelta}
        style={{ fontSize: legendTextFont + 'px', fontWeight: 'bold' }}
      >
        {legendKeys[1]}
      </text>
      <circle cx={circleX} cy={circleY + 2 * yDelta} r={circleR} fill={receiver_colors[2]} />
      <text
        className="legendstext"
        x={textX}
        y={textY + 2 * yDelta}
        style={{ fontSize: legendTextFont + 'px', fontWeight: 'bold' }}
      >
        {legendKeys[2]}
      </text>
      <circle cx={circleX} cy={circleY + 3 * yDelta} r={circleR} fill={receiver_colors[3]} />
      <text
        className="legendstext"
        x={textX}
        y={textY + 3 * yDelta}
        style={{ fontSize: legendTextFont + 'px', fontWeight: 'bold' }}
      >
        {legendKeys[3]}
      </text>
    </g>
  );
};

type GraphInnerProps = {
  graph: GraphData[][];
  width: number;
  height: number;
  mod: number; // Primarily for the legends tab, it scales down elements based on screen size
  graphDisplay: string;
  customYAxisMax?: number;
  customYAxisMin?: number;
};

const GraphInner: FC<GraphInnerProps> = ({
  graph,
  width,
  height,
  mod,
  graphDisplay,
  customYAxisMax,
  customYAxisMin,
}) => {
  const margin = { top: 20, right: 15, bottom: 30, left: 30 };
  const outerWidth = width;
  const outerHeight = height;
  const gWidth = outerWidth - margin.left - margin.right;
  const gHeight = outerHeight - margin.top - margin.bottom;

  const legendsMarginTop = margin.top * 6 * mod;
  const legendsMarginRight = margin.right * 16 * mod;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const yMin = useRef(new Array(10));
  const yMax = useRef(new Array(10));

  const x_min = graph[0][0].px;
  const x_max = graph[0][graph[0].length - 1].px;

  let _yMx = customYAxisMax || 100;
  let _yMn = customYAxisMin || 0;

  if (graphDisplay === 'auto') {
    const y_extents = graph.map((item) => {
      return extent(item, (d) => d.py);
    });
    const y_min = min(y_extents, (d) => d[0]);
    const y_max = max(y_extents, (d) => d[1]);

    yMax.current.shift();
    yMax.current.push(y_max);
    yMin.current.shift();
    yMin.current.push(y_min);

    _yMx = max(yMax.current);
    _yMn = min(yMin.current);

    // Gives the automatic graph some padding
    _yMx += 10;
    _yMn -= 10;
  }

  const xScale = scaleLinear().domain([x_min, x_max]).range([0, gWidth]).nice();

  const yScale = scaleLinear().domain([_yMn, _yMx]).range([gHeight, 0]).nice();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) return; // TODO: have a look at these later
    const context = canvas.getContext('2d');
    if (context == null) return;

    var nWidth = context.canvas.clientWidth;
    var nHeight = context.canvas.clientHeight;

    context.canvas.width = nWidth;
    context.canvas.height = nHeight;

    const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
      const px = xScale(x);
      const py = yScale(y);
      ctx.lineTo(px, py);
    };

    const draw = (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      if (!graph) {
        return;
      }

      graph.forEach((r, idx) => {
        ctx.beginPath();
        // ctx.moveTo(xScale(0),yScale(0))
        ctx.strokeStyle = receiver_colors[idx];
        ctx.lineWidth = 1;
        r.forEach((d) => {
          drawPoint(ctx, d.px, d.py);
        });
        ctx.stroke();
      });
    };

    draw(context);
  }, [graph, xScale, yScale]);

  const xAxis = axisBottom(xScale);
  const yAxis = axisLeft(yScale);
  const xAxisTop = axisTop(xScale);
  const xTransform = `translate(${margin.left}, ${outerHeight - margin.bottom})`;
  const yTransform = `translate(${margin.left}, ${margin.top})`;
  const legendsTransform = `translate(${outerWidth - legendsMarginRight}, ${legendsMarginTop})`;

  const xGrid = axisBottom(xScale); // Have to do this, otherwise the CSS for the xAxis and yAxis overwrites the grid CSS
  const yGrid = axisLeft(yScale);

  return (
    <div>
      <div className="scatter-container" style={{ width: outerWidth, height: outerHeight }}>
        <canvas
          className="canvas-plot"
          ref={canvasRef}
          style={{
            width: outerWidth - margin.left,
            height: outerHeight - margin.top,
            marginLeft: margin.left + 'px',
            marginTop: margin.top + 'px',
          }}
        />
        <svg className="svg-plot" width={outerWidth} height={outerHeight}>
          <g transform={xTransform}>
            <AxisConstructor axisCreator={xAxis} />
            <GraphGridConstructor graphCreator={xGrid} tickSize={-gHeight} />
          </g>
          <g transform={yTransform}>
            <AxisConstructor axisCreator={yAxis} />
            <GraphGridConstructor graphCreator={yGrid} tickSize={-gWidth} />
          </g>
          <g transform={yTransform}>
            <AxisConstructor axisCreator={xAxisTop} />
          </g>
          <g>
            <Legends legendsTransform={legendsTransform} mod={mod} />
          </g>
        </svg>
      </div>
    </div>
  );
};

export function BucketSampler() {
  // https://github.com/d3fc/d3fc-sample/blob/master/src/bucket.js
  var bucketSize = 10;

  var bucket: any = (data: any) =>
    bucketSize <= 1
      ? data.map((d: any) => [d])
      : range(0, Math.ceil(data.length / bucketSize)).map((i) => data.slice(i * bucketSize, (i + 1) * bucketSize));

  bucket.bucketSize = function (x: number) {
    if (!arguments.length) {
      return bucketSize;
    }

    bucketSize = x;
    return bucket;
  };
  return bucket;
}

function killInf(val: number) {
  // Remove negative infinity from the graph
  if (val < -1e9) {
    return NaN;
  }
  return val;
}

function downsample(
  payload: number[][],
  x_scale: number,
  y_scale: number,
  x_offset: number,
  y_offset: number,
  url: any
) {
  // Downsample to 1 entry pr pixel in chart (if needed)
  //var dataPrPixel = payload.length / this.width // Downsample to number of pixels available (responsive)
  let dataPrPixel = payload.length / 500; // Downsample to fixed number of entries

  let s0: number[] = payload.map(function (d) {
    return d[0];
  });
  let s1: number[] = payload.map(function (d) {
    return d[1];
  });
  let s2: number[] = payload.map(function (d) {
    return d[2];
  });
  let s3: number[] = payload.map(function (d) {
    return d[3];
  });

  if (dataPrPixel > 1) {
    // Downsample with BucketSampler
    let bs = BucketSampler();
    let buckets = bs.bucketSize(dataPrPixel);

    payload = buckets(payload).map(function (d: any) {
      return Math.max(...d);
    });
  } else {
    // Use data without downsampling
    dataPrPixel = 1;
  }

  let geometry: any = [[], [], [], []];
  for (let index = 0; index < payload.length; index++) {
    const px = index * dataPrPixel * x_scale + x_offset;
    y_scale = 1;
    geometry[0][index] = { px: px, py: y_offset + y_scale * killInf(s0[index]) };
    geometry[1][index] = { px: px, py: y_offset + y_scale * killInf(s1[index]) };
    geometry[2][index] = { px: px, py: y_offset + y_scale * killInf(s2[index]) };
    geometry[3][index] = { px: px, py: y_offset + y_scale * killInf(s3[index]) };
  }
  return geometry;
}

type CorrelatorGraphProps = {
  defYMax?: number;
  defYMin?: number;
  url: string;
};

interface GraphData {
  px: number;
  py: number;
}
const CorrelatorGraph = observer(({ url, defYMax, defYMin }: CorrelatorGraphProps) => {
  const { authStore } = useStore();
  const [graphDisplay, setGraphDisplay] = useState('fixed');
  const [customYAxisMin, setYAxisMin] = useState(defYMin || -130);
  const [customYAxisMax, setYAxisMax] = useState(defYMax || -5);
  const { data, error } = useSWR(url, (url) => AuthFetcher(url, authStore.getTokenFunction()), {
    refreshInterval: 1000,
    dedupingInterval: 10,
  });

  let ddata: DiagnosticData | undefined = data;
  const lGraph: GraphData[][] = downsample(
    ddata?.data || [[]],
    ddata?.x_scale || 1,
    ddata?.y_scale || 1,
    ddata?.x_offset || 0,
    ddata?.y_offset || 0,
    url
  );
  if (error) {
    return <div>No data available</div>;
  }
  if (!data) return <Loader active></Loader>;

  return (
    <Fragment>
      <GridLayoutPopup
        graphDisplay={graphDisplay}
        setGraphAxisType={setGraphDisplay}
        customYMn={customYAxisMin}
        customYMx={customYAxisMax}
        setCustomYMin={setYAxisMin}
        setCustomYMax={setYAxisMax}
      />

      <Divider hidden />

      {lGraph.length > 0 && (
        <Graph
          graph={lGraph}
          graphDisplay={graphDisplay}
          customYAxisMax={customYAxisMax}
          customYAxisMin={customYAxisMin}
        />
      )}
    </Fragment>
  );
});

// Method detecting the width of the screen for graph scaling
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 600,
    lg: 1024,
    xl: 1920,
  },
});

interface GraphProps {
  graph: GraphData[][];
  graphDisplay: string;
  customYAxisMax?: number;
  customYAxisMin?: number;
}

const Graph: FC<GraphProps> = ({ graph, graphDisplay, customYAxisMax, customYAxisMin }: GraphProps) => {
  return (
    <MediaContextProvider>
      <Media at="sm">
        <GraphInner
          graph={graph}
          graphDisplay={graphDisplay}
          customYAxisMax={customYAxisMax}
          customYAxisMin={customYAxisMin}
          width={320}
          height={128}
          mod={0}
        />
      </Media>
      <Media at="md">
        <GraphInner
          graph={graph}
          graphDisplay={graphDisplay}
          customYAxisMax={customYAxisMax}
          customYAxisMin={customYAxisMin}
          width={700}
          height={280}
          mod={0.5}
        />
      </Media>
      <Media at="lg">
        <GraphInner
          graph={graph}
          graphDisplay={graphDisplay}
          customYAxisMax={customYAxisMax}
          customYAxisMin={customYAxisMin}
          width={1000}
          height={400}
          mod={0.8}
        />
      </Media>
      <Media greaterThanOrEqual="xl">
        <GraphInner
          graph={graph}
          graphDisplay={graphDisplay}
          customYAxisMax={customYAxisMax}
          customYAxisMin={customYAxisMin}
          width={1500}
          height={600}
          mod={1}
        />
      </Media>
    </MediaContextProvider>
  );
};

export default CorrelatorGraph;
