import 'notyf/notyf.min.css';
import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './css/App.css';
import TopsideTopMenu from './TopsideTopMenu';
import TopsideSidebarMenu from './TopsideSidebarMenu';
import TopsideMain from './TopsideMain';

function TopsideApp({ devMode }: TopsideAppProps) {
  const [menuVisible, setVisible] = useState(true);
  const [breadCrumb, setBreadCrumb] = useState('Overview Map');
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setVisible(!menuVisible);
  };

  return (
    <Router>
      <TopsideTopMenu toggleMenu={toggleMenu} breadCrumb={breadCrumb} setBreadCrumb={setBreadCrumb} />
      <TopsideSidebarMenu
        toggleMenu={() => setVisible(!menuVisible)}
        menuVisible={menuVisible}
        breadCrumb={breadCrumb}
        setBreadCrumb={setBreadCrumb}
      />
      <TopsideMain menuVisible={menuVisible} setBreadCrumb={setBreadCrumb} />
    </Router>
  );
}

interface TopsideAppProps {
  devMode: boolean;
}

export default TopsideApp;
